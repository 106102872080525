<style></style>
<!--  @click="modalShowEdit = true"  -->
<template>
	<errorContainer :error="erreur">
		<div>
			<div v-if="$screen.width <= 567">
				<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
					{{ getTitle() }}
				</h1>
				<list-project-mobile
					:projects="myProjects"
					:current-project="myProject"
					@list-project-mobile:change-project="changeProject"
					@list-project-mobile:edit-project="openProjectWindow"
					@list-project-mobile:copy-invoice="copyInvoiceDetails"
					@list-project-mobile:add-project="modalShowCreate = true"
				/>
			</div>
			<div v-else>
				<div class="animated fadeIn">
					<b-row align-h="center">
						<b-col md="12">
							<b-row>
								<b-col :cols="!isFilmSingle ? 12 : 8">
									<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
										{{ getTitle() }}
									</h1>
								</b-col>
								<b-col v-if="isFilmSingle && !isFilmSingleFree" cols="4">
									<b-button class="float-right" @click="modalShowCreate = true">
										<i class="fa fa-plus" /> {{ FormMSG(62, 'Add project') }}
									</b-button>
								</b-col>
								<b-col v-if="isFilmSingle && isFilmSingleFree" cols="4">
									<div class="float-right">
										<subscription-dialog variantBtn="primary" icon="fa fa-plus" :btn-label="FormMSG(62, 'Add project')" />
									</div>
								</b-col>
							</b-row>
							<b-row>
								<b-col>
									<b-card>
										<h2>{{ getHeader() }}</h2>
										<div class="d-flex flex-row justify-content-start align-items-center">
											<b-list-group class="w-100">
												<b-list-group-item
													v-for="project in myProjects"
													:key="project.id"
													action
													href="#"
													:active="myProject && myProject.id === project.id"
													@click="changeProject(project)"
												>
													<h5 class="mb-0 mt-3 pb-1">
														{{ project.titre }}
													</h5>
													<b-row>
														<b-col xl="6">
															<p class="">
																{{ project.production }}
															</p>
														</b-col>
														<b-col xl="6">
															<div class="float-right" style="margin-top: -18px">
																<b-button
																	v-if="myProject && myProject.id === project.id"
																	class="mr-3 btn-md hidden-sm"
																	variant="outline-light"
																	@click="copyInvoiceDetails(project.invoiceDetails)"
																>
																	<i class="icon-envelope-letter" />&nbsp;&nbsp;{{
																		FormMSG(23, 'Copy invoice details to clipboard...')
																	}}
																</b-button>
																<b-button
																	v-if="myProject && myProject.id === project.id && canEditProject()"
																	class="btn-md"
																	variant="outline-light"
																	@click="openProjectWindow"
																>
																	<div class="d-flex justify-content-start align-items-center">
																		<i class="fa fa-edit" style="font-size: 1.15rem" />&nbsp;&nbsp;
																		<div style="margin-top: 0px">{{ FormMSG(52, 'Edit') }}</div>
																	</div>
																</b-button>
															</div>
														</b-col>
													</b-row>
												</b-list-group-item>
											</b-list-group>
										</div>
									</b-card>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
				</div>
			</div>
			<b-modal
				size="xl"
				header-class="header-class-modal-doc-package"
				v-model="modalShowEdit"
				:title="FormMSG(55, 'Edit project')"
				:ok-title="FormMSG(51, 'Save')"
				:cancel-title="FormMSG(53, 'Cancel')"
				@ok.prevent="handleSubmitModalEditProject"
				@cancel.prevent="handleCloseModalEditProject"
				modal-class="mui-animation"
				:fade="false"
			>
				<ProjectForm :is-modal="true" :can-update="canUpdate" @updatefinishevent="onUpdateProjectFinished" />
			</b-modal>
			<b-modal
				size="xl"
				header-class="header-class-modal-doc-package"
				v-model="modalShowCreate"
				:title="FormMSG(56, 'Add project')"
				:ok-title="FormMSG(54, 'Add')"
				:cancel-title="FormMSG(53, 'Cancel')"
				hide-header-close
				no-close-on-backdrop
				no-close-on-esc
				@ok.prevent="handleSubmitModalCreateProject"
				@cancel.prevent="handleCloseModalCreateProtect"
				modal-class="mui-animation"
				:fade="false"
			>
				<ProjectForm
					project-form-id="0"
					:is-create-with-modal="true"
					:is-modal="true"
					:can-create="canCreate"
					:license-id="licenseId"
					@createfinishevent="onCreateProjectFinished"
				/>
			</b-modal>
		</div>
	</errorContainer>
</template>

<script>
import gql from 'graphql-tag';
import { store } from '@/store';
import languageMessages from '@/mixins/languageMessages';
import ProjectForm from './ProjectForm';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import ListProjectMobile from '@/components/MyProjectMobile/ListProjectMobile';
import { mapActions } from 'vuex';
import { myContracts } from '@/cruds/contract.crud';
import SubscriptionDialog from '@/components/InfoSheet/SubscriptionDialog.vue';
import GlobalMixin from '@/mixins/global.mixin';

const query_myProjects = gql`
	query {
		myProjects {
			id
			titre
			production
			invoiceDetails
		}
	}
`;

const query_myProject = gql`
	query {
		myProject {
			id
			titre
			production
			realisateur
			invoiceDetails
		}
	}
`;

// Variable doit être dans la query : https://github.com/graphql/graphiql/issues/156
const mutation_changeProject = gql`
	mutation ($id: ID!) {
		changeProject(projectID: $id)
	}
`;

export default {
	name: 'Myproject',
	components: {
		ProjectForm,
		ListProjectMobile,
		SubscriptionDialog
	},
	mixins: [languageMessages, isSingleProjectMixin, GlobalMixin],
	apollo: {
		myProjects: {
			query: query_myProjects,
			fetchPolicy: 'no-cache',
			error(err) {
				console.log('err:', err);
				this.erreur = err;
			}
		},
		myProject: {
			query: query_myProject,
			fetchPolicy: 'no-cache',
			error(err) {
				console.log('err:', err);
				this.erreur = err;
			}
		}
	},
	data: function () {
		return {
			// Initialize your apollo data
			erreur: {},
			myerr: '',
			shared: store.state,
			modalShowEdit: false,
			modalShowCreate: false,
			canUpdate: false,
			canCreate: false,
			licenseId: null
		};
	},
	mounted() {
		this.$nextTick(() => {
			if (this.isFilmSingle) {
				// console.log("STORE", store.state.myProject.licenseID);
				this.licenseId = store.state.myProject.licenseID;
			}
		});
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		canEditProject() {
			// instead of isFilmSingle
			if (store.isFilmSingleUserFree() || store.isFilmSingleUser()) {
				return true;
			} else {
				return false;
			}
		},
		openProjectWindow() {
			this.$router.push('/settings');
		},
		getTitle() {
			if (store.isPme()) {
				return this.FormMSG(110, 'My Office');
			}
			return this.FormMSG(10, 'My project');
		},
		getHeader() {
			if (store.isPme()) {
				return this.FormMSG(111, ' What Legal Entity do you want to work on?');
			}
			return this.FormMSG(11, ' What project do you want to work on?');
		},
		copyInvoiceDetails(invoiceDetails) {
			console.log('invoice:', invoiceDetails);
			var temp = document.createElement('textarea');
			var tempMsg = document.createTextNode(invoiceDetails);
			temp.appendChild(tempMsg);

			document.body.appendChild(temp);
			temp.select();
			document.execCommand('copy');
			document.body.removeChild(temp);
			console.log('Success!');
		},
		async changeProject(project) {
			const id = parseInt(project.id, 10);
			await this.$apollo
				.mutate({
					mutation: mutation_changeProject,
					variables: {
						id
					}
				})
				.then(async (result) => {
					await store.onNewToken(this.$apollo.provider.defaultClient, result.data.changeProject); // executer onNewToken avant de storer le nouveau projet, sinon supprimé
					// console.log(result.data.changeProject)
					store.setProjectAction(project);
					const tmp = await myContracts();
					// console.log("in myContract DefaultHeaderDropdownAccnt tmp:",tmp);
					store.state.myContracts = tmp;
					this.myerr = '';
					await this.getNotifications(project.id);
				})
				.catch((error) => {
					this.erreur = error;
				});
		},
		handleSubmitModalEditProject() {
			this.canUpdate = true;
			//this.modalShowEdit = false
		},
		handleSubmitModalCreateProject() {
			this.canCreate = true;
		},
		onUpdateProjectFinished() {
			this.canUpdate = false;
			this.modalShowEdit = false;
			this.$toast({
				message: this.FormMSG(59, 'Update project successfull'),
				title: this.FormMSG(60, 'Success'),
				variant: 'success'
			});
		},
		async onCreateProjectFinished(payload) {
			this.canCreate = false;
			this.modalShowCreate = false;
			this.myProject = null;
			this.myProjects = [];

			const {
				data: { myProjects }
			} = await this.$apollo.query({
				query: query_myProjects,
				fetchPolicy: 'no-cache'
			});

			this.myProject = payload;
			this.myProjects = myProjects;

			this.createToastForMobile(this.FormMSG(60, 'Success'), this.FormMSG(61, 'Create project successfull'));

			await this.changeProject(this.myProject);
		},
		handleCloseModalEditProject() {
			this.canUpdate = false;
			this.modalShowEdit = false;
		},
		handleCloseModalCreateProtect() {
			this.modalShowCreate = false;
		}
	}
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
	width: 100% !important;
}
</style>
